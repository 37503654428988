
import { Component, Prop, Watch, Emit, Vue } from "vue-property-decorator";

@Component({
  filters: {
    addComma: function (value: number): string {
      return value.toLocaleString();
    },
  },
})
export default class OrderDetail extends Vue {
  @Prop() private orderDetail!: { [key: string]: { [key: string]: string }[] };
  @Prop() private isOpend!: boolean;
  @Prop() private totalPrice!: number;

  private tweetMessage: string = "";
  private toppingTweetMaxStr: number = 90;

  private styleObject: { [key: string]: string } = {
    height: "0px",
    overflow: "scroll",
    paddingBottom: "20px",
  };

  private created(): void {
    this.setStyle();
    window.addEventListener("resize", () => {
      this.setStyle();
    });
  }

  @Emit()
  private closeDetail(): void {
    const detailArea: any = document.querySelector(".menu.order-detail-area");
    if (detailArea !== null) {
      detailArea.classList.remove("open");
      document
        .getElementsByTagName("body")[0]
        .classList.remove("has-open-detail");
    }
  }

  @Watch("isOpend")
  private generateTweetMessage(): void {
    if (this.isOpend !== true) {
      return;
    }

    const totalPriceStr =
      typeof this.totalPrice !== "undefined"
        ? this.totalPrice.toLocaleString()
        : "0";
    let message = "";
    message += `${this.orderDetail.source[0].name} ${this.orderDetail.rice[0].name} 辛さ${this.orderDetail.spicy[0].name}\n`;
    let toppingTmp = "";
    for (let idx in this.orderDetail.topping) {
      if (toppingTmp !== "") {
        toppingTmp += ", ";
      }
      toppingTmp += this.orderDetail.topping[idx].name;
    }

    if (toppingTmp !== "") {
      if (toppingTmp.length > this.toppingTweetMaxStr) {
        toppingTmp = toppingTmp.substr(0, this.toppingTweetMaxStr) + "...";
      }
      message += `\n${toppingTmp}\n`;
    }
    message += `\n料金 ${totalPriceStr} 円 #ココイチ #CoCo壱番屋\n\n`;

    this.tweetMessage = encodeURIComponent(message);
  }

  private setStyle(): void {
    const windowHeight = window.innerHeight;
    this.styleObject.height = `${windowHeight - 115}px`;
  }
}
