import { render, staticRenderFns } from "./MenuSpicy.vue?vue&type=template&id=21398902&scoped=true"
import script from "./MenuSpicy.vue?vue&type=script&lang=ts"
export * from "./MenuSpicy.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21398902",
  null
  
)

export default component.exports