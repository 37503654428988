
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class MenuSideMenu extends Vue {
  private sideMenuData: any = {};
  private allToppingCount: number = 0;

  private created(): void {
    const sideMenuDataUrl = "/data/side-menu.json";
    fetch(sideMenuDataUrl)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            this.sideMenuData = json;

            this.allToppingCount =
              this.sideMenuData.salad.length +
              this.sideMenuData.beverages.length +
              this.sideMenuData.dessert.length +
              this.sideMenuData.others.length;
          });
        } else {
          throw new Error(`Failed to get side menu data: ${response.status}`);
        }
      })
      .catch((err) => {
        console.error(err); // eslint-disable-line no-console
      });
  }

  private mounted(): void {
    const allRandomBtn: any = document.getElementById("all-random-btn");
    if (allRandomBtn !== null) {
      allRandomBtn.addEventListener(
        "click",
        () => {
          this.selectRandom(0);
        },
        false
      );
    }
  }

  private selectRandom(count: number): void {
    while (count === 0) {
      count = Math.floor(Math.random() * this.allToppingCount);
    }
    const selectIdxList = this.generateRandomNumberList(count);
    let checkIdx = 0;
    let selectedCnt = 0;
    for (const kind in this.sideMenuData) {
      this.sideMenuData[kind].forEach(
        (sideMenu: { [key: string]: any }, index: number) => {
          this.sideMenuData[kind][index].selected = "";
          if (selectIdxList.includes(checkIdx) === true) {
            this.sideMenuData[kind][index].selected = "selected";
            selectedCnt++;
          }

          checkIdx++;
          if (selectedCnt === count) {
            return;
          }
        }
      );
    }

    this.calcSubTotal();
  }

  private onClick(event: any): void {
    const clickedIdx = parseInt(event.target.dataset.idx, 10);
    const clickedKind = event.target.dataset.kind;
    if (this.sideMenuData[clickedKind][clickedIdx].selected === "") {
      this.sideMenuData[clickedKind][clickedIdx].selected = "selected";
    } else {
      this.sideMenuData[clickedKind][clickedIdx].selected = "";
    }
    this.calcSubTotal();
  }

  private calcSubTotal(): void {
    let selectedSideMenuList: { [key: string]: string }[] = [];
    let subTotal = 0;
    for (const kind in this.sideMenuData) {
      this.sideMenuData[kind].forEach((sideMenu: { [key: string]: any }) => {
        if (sideMenu.selected === "selected") {
          subTotal += sideMenu.price;
          selectedSideMenuList.push({
            name: sideMenu.name,
            priceStr: sideMenu.price.toLocaleString() + " 円",
          });
        }
      });
    }
    this.changeOrder("side-menu", subTotal, selectedSideMenuList);
  }

  private generateRandomNumberList(count: number): number[] {
    let numList: number[] = [];
    let randTmp: number = 0;
    while (numList.length < count) {
      randTmp = Math.floor(Math.random() * this.allToppingCount);
      if (numList.indexOf(randTmp) < 0) {
        numList.push(randTmp);
      }
    }

    return numList;
  }

  @Emit() private changeOrder(
    menu: string,
    subTotal: number,
    selectedSideMenuList: { [key: string]: string }[]
  ): void {
    /** empty */
  }
}
