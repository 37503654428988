
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class MenuSpicy extends Vue {
  private spicyLevelData: { [key: string]: { [key: string]: any }[] } = {
    sweet: [
      { level: "甘口", price: 0 },
      { level: "１甘", price: 22 },
      { level: "２甘", price: 44 },
      { level: "３甘", price: 66 },
      { level: "４甘", price: 88 },
      { level: "５甘", price: 110 },
    ],
    spicy: [
      { level: "普通", price: 0 },
      { level: "１辛", price: 22 },
      { level: "２辛", price: 44 },
      { level: "３辛", price: 66 },
      { level: "４辛", price: 88 },
      { level: "５辛", price: 110 },
    ],
  };

  private selectedSpicyIdx = 0;
  private selectedSpicyKind = "spicy";

  private mounted(): void {
    this.calcSubTotal();

    const allRandomBtn: any = document.getElementById("all-random-btn");
    if (allRandomBtn !== null) {
      allRandomBtn.addEventListener(
        "click",
        () => {
          this.selectRandom();
        },
        false
      );
    }
  }

  private onClick(event: any): void {
    this.selectedSpicyIdx = parseInt(event.target.dataset.idx, 10);
    this.selectedSpicyKind = event.target.dataset.kind;
    this.calcSubTotal();
  }

  private calcSubTotal(): void {
    document.querySelectorAll(".menu-item.spicy-level").forEach((elem: any) => {
      elem.classList.remove("selected");
    });

    const selected = document.getElementById(
      `menu-spicy-${this.selectedSpicyKind}-${this.selectedSpicyIdx}`
    );
    if (selected === null) {
      this.changeOrder("spicy", 0, "普通");
    } else {
      selected.classList.add("selected");
      this.changeOrder(
        "spicy",
        this.spicyLevelData[this.selectedSpicyKind][this.selectedSpicyIdx]
          .price,
        this.spicyLevelData[this.selectedSpicyKind][this.selectedSpicyIdx].level
      );
    }
  }

  private selectRandom(): void {
    const spicyTypeIdx = Math.floor(Math.random() * 2);
    const spicyType = spicyTypeIdx === 0 ? "spicy" : "sweet";
    const allCount = this.spicyLevelData[spicyType].length;
    this.selectedSpicyIdx = Math.floor(Math.random() * allCount);
    this.selectedSpicyKind = spicyType;
    this.calcSubTotal();
  }

  @Emit() private changeOrder(
    menuType: string,
    subTotal: number,
    menu: string
  ): void {
    /** empty */
  }
}
