
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class MenuRice extends Vue {
  private riceData = [
    { amount: 200, price: -52 },
    { amount: 250, price: -26 },
    { amount: 300, price: 0 },
    { amount: 350, price: 55 },
    { amount: 400, price: 110 },
    { amount: 500, price: 220 },
    { amount: 600, price: 330 },
    { amount: 700, price: 440 },
    { amount: 800, price: 550 },
  ];

  private selectedRiceIdx = 2; // default '300g'

  private mounted(): void {
    this.calcSubTotal();

    const allRandomBtn: any = document.getElementById("all-random-btn");
    if (allRandomBtn !== null) {
      allRandomBtn.addEventListener(
        "click",
        () => {
          this.selectRandom();
        },
        false
      );
    }
  }

  private onClick(event: any): void {
    this.selectedRiceIdx = parseInt(event.target.dataset.idx, 10);
    this.calcSubTotal();
  }

  private calcSubTotal(): void {
    document.querySelectorAll(".menu-item.rice").forEach((elem: any) => {
      elem.classList.remove("selected");
    });

    const selected = document.getElementById(
      `menu-rice-${this.selectedRiceIdx}`
    );
    if (selected === null) {
      this.changeOrder("rice", 0, "300g");
    } else {
      selected.classList.add("selected");
      this.changeOrder(
        "rice",
        this.riceData[this.selectedRiceIdx].price,
        `${this.riceData[this.selectedRiceIdx].amount}g`
      );
    }
  }

  private selectRandom(): void {
    const allCount = this.riceData.length;
    this.selectedRiceIdx = Math.floor(Math.random() * allCount);
    this.calcSubTotal();
  }

  @Emit() private changeOrder(
    menuType: string,
    subTotal: number,
    menu: string
  ): void {
    /** empty */
  }
}
