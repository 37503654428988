
import { Component, Vue } from "vue-property-decorator";
import MenuRice from "./components/MenuRice.vue";
import MenuSideMenu from "./components/MenuSideMenu.vue";
import MenuSource from "./components/MenuSource.vue";
import MenuSpicy from "./components/MenuSpicy.vue";
import MenuTopping from "./components/MenuTopping.vue";
import OrderDetail from "./components/OrderDetail.vue";
import TotalPrice from "./components/TotalPrice.vue";

@Component({
  components: {
    MenuRice,
    MenuSideMenu,
    MenuSource,
    MenuSpicy,
    MenuTopping,
    OrderDetail,
    TotalPrice,
  },
})
export default class App extends Vue {
  public totalPrice = 0;
  public sourcePrice = 0;
  public ricePrice = 0;
  public spicyPrice = 0;
  public toppingPrice = 0;
  public sideMenuPrice = 0;
  public isDetailOpend: boolean = false;
  public orderDetail: { [key: string]: { [key: string]: string }[] } = {
    source: [{ name: "", priceStr: "" }],
    rice: [{ name: "", priceStr: "" }],
    spicy: [{ name: "", priceStr: "" }],
    topping: [],
    sideMenu: [],
  };

  public toppingUrl = "https://www.ichibanya.co.jp/menu/topping.html";

  private calcSubTotal(menuType: string, subTotal: number, menu: any): void {
    switch (menuType) {
      case "source":
        this.sourcePrice = subTotal;
        this.orderDetail.source[0].name = menu;
        this.orderDetail.source[0].priceStr = subTotal.toLocaleString() + " 円";
        break;

      case "rice":
        this.ricePrice = subTotal;
        this.orderDetail.rice[0].name = menu;
        this.orderDetail.rice[0].priceStr = subTotal.toLocaleString() + " 円";
        break;

      case "spicy":
        this.spicyPrice = subTotal;
        this.orderDetail.spicy[0].name = menu;
        this.orderDetail.spicy[0].priceStr = subTotal.toLocaleString() + " 円";
        break;

      case "topping":
        this.toppingPrice = subTotal;
        this.orderDetail.topping = menu;
        break;

      case "side-menu":
        this.sideMenuPrice = subTotal;
        this.orderDetail.sideMenu = menu;
        break;
    }

    this.calcTotal();
  }

  private calcTotal(): void {
    const newTotal =
      this.sourcePrice +
      this.ricePrice +
      this.spicyPrice +
      this.toppingPrice +
      this.sideMenuPrice;

    this.totalPrice = newTotal;
  }

  private openDetail(): void {
    this.isDetailOpend = true;
  }

  private closeDetail(): void {
    this.isDetailOpend = false;
  }

  private superReload(): void {
    window.location.reload();
  }
}
