
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component({
  filters: {
    addComma: function (value: number): string {
      return value.toLocaleString();
    },
  },
})
export default class TotalPrice extends Vue {
  @Prop() private total!: number;

  private onClickReset(): void {
    location.href = "/";
  }

  @Emit()
  private openDetail(): void {
    const detailArea: any = document.querySelector(".menu.order-detail-area");
    if (detailArea !== null) {
      detailArea.classList.add("open");
      document.getElementsByTagName("body")[0].classList.add("has-open-detail");
    }
  }
}
