
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class MenuSource extends Vue {
  private sourceData = [
    { name: "ポーク", price: 514 },
    { name: "甘口ポーク", price: 514 },
    { name: "ビーフ", price: 641 },
    { name: "ハッシュドビーフ", price: 641 },
    { name: "ココイチベジソース", price: 530 },
  ];

  private selectedSourceIdx = 0;

  private mounted(): void {
    this.calcSubTotal();

    const allRandomBtn: any = document.getElementById("all-random-btn");
    if (allRandomBtn !== null) {
      allRandomBtn.addEventListener(
        "click",
        () => {
          this.selectRandom();
        },
        false
      );
    }
  }

  private onClick(event: any): void {
    this.selectedSourceIdx = parseInt(event.target.dataset.idx, 10);
    this.calcSubTotal();
  }

  private calcSubTotal(): void {
    document.querySelectorAll(".menu-item.source").forEach((elem: any) => {
      elem.classList.remove("selected");
    });

    const selected = document.getElementById(
      `menu-source-${this.selectedSourceIdx}`
    );
    if (selected === null) {
      this.changeOrder("source", 0, "ポーク");
    } else {
      selected.classList.add("selected");
      this.changeOrder(
        "source",
        this.sourceData[this.selectedSourceIdx].price,
        this.sourceData[this.selectedSourceIdx].name
      );
    }
  }

  private selectRandom(): void {
    const allCount = this.sourceData.length;
    this.selectedSourceIdx = Math.floor(Math.random() * allCount);
    this.calcSubTotal();
  }

  @Emit() private changeOrder(
    menuType: string,
    subTotal: number,
    menu: string
  ): void {
    /** empty */
  }
}
